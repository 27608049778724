import { call, put, takeLatest } from "redux-saga/effects";

import api from "../../../config/api";

import {
  flightStatusByRouteFailure,
  flightStatusByRouteSuccess,
  flightStatusByRouteRequest,
} from "../slice/flightRouteStatusSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { FlightStatusByRoute } from "../models/models";

const FLIGHT_STATUS_ROUTE_URL = "flights/flight-status/by-route";

const accessToken = sessionStorage.getItem("accesstoken");

const FlightStatusRouteData = async (payload: any) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
    },
    loader: true,
  };
  let flightStatusPayload = {
    departureDate: payload?.date,
    departure: payload?.from?.airportCode,
    arrival: payload?.to?.airportCode,
  };
  const response = await api.post(
    FLIGHT_STATUS_ROUTE_URL,
    flightStatusPayload,
    data
  );
  return response;
};

function* getFlightStatusByRouteData(
  action: PayloadAction<FlightStatusByRoute>
): any {
  try {
    const { payload } = action;
    const response: any = yield call(FlightStatusRouteData, payload);
    yield put(flightStatusByRouteSuccess(response.data));
  } catch (error: any) {
    yield put(flightStatusByRouteFailure(error.message));
  }
}

export function* watchFlightStatusByRoute(): any {
  yield takeLatest(flightStatusByRouteRequest.type, getFlightStatusByRouteData);
}
