import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../config/api";
import { CRMOTPFormData } from "../models/models";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  sendCRMOTPFailure,
  sendCRMOTPPending,
  sendCRMOTPSuccess,
} from "../slice/crmOTPSlice";
import { config } from "../../../config/global";

const API_URL = config?.CRM_LOGIN_OTP;

const sendCRMLoginOTPData = async (crmotp: CRMOTPFormData) => {
  const data = {
    otpNumber: crmotp.otpNumber,
  };

  const headerData = {
    headers: {
      Authorization: `${sessionStorage.getItem("temptoken")}`,
      "content-type": "application/json",
      // "Access-Control-Expose-Headers":"Authorization"
    },
  };
  const response = await api.post(API_URL, data, headerData);

  return response;
};

function* sendCRMOTPData(
  action: PayloadAction<CRMOTPFormData>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(sendCRMLoginOTPData, payload);
    yield put(sendCRMOTPSuccess(response.data));
    sessionStorage.setItem("accesstoken", response?.headers?.authorization);
  } catch (error: any) {
    yield put(sendCRMOTPFailure(error));
  }
}

export function* watchSendCRMOTPData(): any {
  yield takeLatest(sendCRMOTPPending.type, sendCRMOTPData);
}
