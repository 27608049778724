import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getCrmSignUpDataPending,
  getCrmSignUpSocialMediaDataPending,
  getCrmSignUpSuccess,
  getCrmSignUpocialMediaSuccess,
  getCrmSignupFailure,
} from "../slice/crmSignUpSlice";

import { CRMPayloadData, CRMRequestObject } from "../models/models";
import { config } from "../../../config/global";

const CRM_SIGNUP_API_URL = "mythai/enrol";
const CRM_SIGNUP_SOCIAL_MEDIA_URL = config.SOCIAL_MEDIA_SIGN_UP;

const fetchCrmSignupData = async (crmPayload: CRMPayloadData) => {
  let CrmSignupPayloadData: CRMRequestObject = {
    title: crmPayload.title,
    firstName: crmPayload.firstName,
    lastName: crmPayload.lastName,
    dateofbirth: crmPayload.dob,
    email: crmPayload.email,
    mobile: crmPayload.contactNumber,
    countryCode:crmPayload.countryCode,
    parentDateofbirth: "1000-01-01",
    consent: crmPayload.consent,
    subscription: crmPayload.thaiPromoConsent,
    //thirdPartySubscription: crmPayload.thaiThirdPartyPromoconsent
  };
  CrmSignupPayloadData = crmPayload.consent
    ? {
        ...CrmSignupPayloadData,
        parentFirstName: crmPayload.parentFirstName,
        parentLastName: crmPayload.parentLastName,
      }
    : CrmSignupPayloadData;
  CrmSignupPayloadData = crmPayload.thaiPromoConsent
    ? {
        ...CrmSignupPayloadData,
        countryOfResidence: crmPayload.countryOfResidence,
        preferredNews: crmPayload.preferredNews,
        airportCode: crmPayload.airportCode,
      }
    : CrmSignupPayloadData;
  CrmSignupPayloadData = crmPayload.thaiThirdPartyPromoconsent
    ? {
        ...CrmSignupPayloadData,
        subscriptionPartnersThai: crmPayload.thaiThirdPartyPromoconsent,
      }
    : CrmSignupPayloadData;

  const response = await api.post(CRM_SIGNUP_API_URL, CrmSignupPayloadData);

  return response;
};

function* getCrmSignupData(
  action: PayloadAction<CRMPayloadData>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchCrmSignupData, action.payload);
    yield put(getCrmSignUpSuccess(response.data));
  } catch (error: any) {
    yield put(getCrmSignupFailure(error));
  }
}

const fetchCrmSignupSocialMediaData = async (crmPayload: any) => {
  let authorization = sessionStorage.getItem("accesstoken");
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: authorization,
    },
    loader: true,
  };
  let CrmSignUpSocialMediaPayloadData: any = {
    title: crmPayload.title,
    firstName: crmPayload.firstName,
    lastName: crmPayload.lastName,
    dateofbirth: crmPayload.dob,
    email: crmPayload.email,
    mobile: crmPayload.contactNumber,
    subscription: crmPayload.thaiPromoConsent,
    parentDateofbirth: "1000-01-01",
    clientId: crmPayload.clientId,
    socialMediaType: crmPayload.socialMediaType,
    // consent: crmPayload.consent,

    //thirdPartySubscription: crmPayload.thaiThirdPartyPromoconsent
  };
  CrmSignUpSocialMediaPayloadData = crmPayload.consent
    ? {
        ...CrmSignUpSocialMediaPayloadData,
        parentFirstName: crmPayload.parentFirstName,
        parentLastName: crmPayload.parentLastName,
        // parentEmail:crmPayload.parentEmail,
        // parentMobileNumber:crmPayload.parentMobileNumber
      }
    : CrmSignUpSocialMediaPayloadData;
  CrmSignUpSocialMediaPayloadData = crmPayload.thaiPromoConsent
    ? {
        ...CrmSignUpSocialMediaPayloadData,
        // countryOfResidence: crmPayload.countryOfResidence,
        preferredNews: crmPayload.preferredNews,
        airportCode: crmPayload.airportCode,
      }
    : CrmSignUpSocialMediaPayloadData;
  // CrmSignUpSocialMediaPayloadData = crmPayload.thaiThirdPartyPromoconsent
  //   ? {
  //       ...CrmSignUpSocialMediaPayloadData,
  //       subscriptionPartnersThai: crmPayload.thaiThirdPartyPromoconsent,
  //     }
  //   : CrmSignUpSocialMediaPayloadData;

  const response = await api.post(
    CRM_SIGNUP_SOCIAL_MEDIA_URL,
    CrmSignUpSocialMediaPayloadData,
    data
  );
  response.data.email = crmPayload.email;

  return response;
};

function* getCrmSignupSocialMediaData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchCrmSignupSocialMediaData, action.payload);
    yield put(getCrmSignUpocialMediaSuccess(response.data));
  } catch (error: any) {
    yield put(getCrmSignUpocialMediaSuccess(error));
  }
}

export function* watchCrmSignupSocialMediaData(): any {
  yield takeLatest(
    getCrmSignUpSocialMediaDataPending.type,
    getCrmSignupSocialMediaData
  );
}

export function* watchCrmSignupData(): any {
  yield takeLatest(getCrmSignUpDataPending.type, getCrmSignupData);
}
