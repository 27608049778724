import { call, put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";
import api from "../../../config/api";
import {
  deleteTripCardDataRequest,
  deleteTripCardDataSuccess,
  deleteTripCardDataFailure,
} from "../slice/deleteTripCardSlice";

const API_URL = config?.DELETE_TRIP_CARD;

const deleteTriCardData = async (payload: string) => {
  let headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      memberId: sessionStorage.getItem("memberId"),
    },
    loader: true,
    data: {
      pnr: payload,
    },
  };

  const response = await api.delete(API_URL, headerData);

  return response;
};
function* deleteTriCardDataSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(deleteTriCardData, payload);
    yield put(
      deleteTripCardDataSuccess({
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      deleteTripCardDataFailure({
        ...error?.response,
      })
    );
  }
}

export function* watchDeleteTripCardData() {
  yield takeEvery(deleteTripCardDataRequest.type, deleteTriCardDataSaga);
}
