import { call, put, takeEvery } from "redux-saga/effects";
import {
  getGiftMilesDropdownFailure,
  getGiftMilesDropdownRequest,
  getGiftMilesDropdownSuccess,
  reviewSummaryFailure,
  reviewSummaryRequest,
  reviewSummarySuccess,
} from "../slice/giftMilesSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";

const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId")
const headerData = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: accessToken,
    memberId
  },
};
const fetchDropdownData = async () => {
  const DROPDOWN_API_URL = config.FETCH_GIFT_MILES_DROPDOWN;
  const response = await api.get(DROPDOWN_API_URL, headerData);
  return response;
};

const fetchReviewSummary = async (payload:any) => {
  console.log(payload,"fetchReviewSummary");
  
  const REVIEW_SUMMARY_URL = config.REVIEW_SUMMARY_GIFT_MILES;
  let payloadData = {
    firstNameMiddleName:payload?.firstName,
    lastName:payload?.lastName,
    giftingMiles:payload?.giftingMiles,
    currency:payload?.currency,
    sponseeId:payload?.receipientROPId
}
  const response = await api.post(REVIEW_SUMMARY_URL,payloadData,headerData)

  return response;
};
function* getDropdownData(): any {
  try {
    const response = yield call(fetchDropdownData);
    yield put(getGiftMilesDropdownSuccess(response?.data));
  } catch (error: any) {
    yield put(getGiftMilesDropdownFailure(error.message));
  }
}

function* getReviewSummary(action:any): any {
  const {payload}=action
  try {
    const response = yield call(fetchReviewSummary,payload);
    yield put(reviewSummarySuccess(response?.data));
  } catch (error: any) {
    yield put(reviewSummaryFailure(error.message));
  }
}

export function* watchGiftMilesDropdownData() {
  yield takeEvery(getGiftMilesDropdownRequest.type, getDropdownData);
}

export function* watchGiftMilesReviewSummaryData() {
  yield takeEvery(reviewSummaryRequest.type, getReviewSummary);
}
