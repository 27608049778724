import { call, put, takeLatest } from "redux-saga/effects";

import api from "../../../config/api";

import {
  flightStatusByNumberFailure,
  flightStatusByNumberSuccess,
  flightStatusByNumberRequest,
} from "../slice/flightStatusSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { FlightStatusByNumber } from "../models/models";

const FLIGHT_STATUS_NUMBER_URL = "flights/flight-status/by-number";

const accessToken = sessionStorage.getItem("accesstoken");

const FlightStatusNumberData = async (payload: any) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
    },
    loader: true,
  };
  let flightStatusPayload = {
    departureDate: payload.date,
    flightNum: payload.flightNumber,
  };
  const response = await api.post(
    FLIGHT_STATUS_NUMBER_URL,
    flightStatusPayload,
    data
  );
  return response;
};

function* getFlightStatusByNumberData(
  action: PayloadAction<FlightStatusByNumber>
): any {
  try {
    const { payload } = action;
    const response: any = yield call(FlightStatusNumberData, payload);
    yield put(flightStatusByNumberSuccess(response.data));
  } catch (error: any) {
    yield put(flightStatusByNumberFailure(error.message));
  }
}

export function* watchFlightStatusByNumber(): any {
  yield takeLatest(
    flightStatusByNumberRequest.type,
    getFlightStatusByNumberData
  );
}
