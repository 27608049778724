import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { crmServiceRequest, crmServiceRequestFailure, submitFormSuccess } from "../slice/crmServiceRequestSlice";
import { config } from "../../../../src/config/global";
const CRM_Service_URL = config === null || config === void 0 ? void 0 : config.CRM_SERVICE_REQUEST;
const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const CrmServiceRequestData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
      memberId
    },
    loader: true
  };
  let CrmRequestPayload = {
    memberId: payload === null || payload === void 0 ? void 0 : payload.memberId,
    bookingEmail: payload === null || payload === void 0 ? void 0 : payload.bookingEmail,
    pnr: payload === null || payload === void 0 ? void 0 : payload.pnr,
    flightNumber: payload === null || payload === void 0 ? void 0 : payload.flightNumber,
    flightDate: payload === null || payload === void 0 ? void 0 : payload.flightDate,
    ticketNumber: payload === null || payload === void 0 ? void 0 : payload.ticketNumber,
    description: payload === null || payload === void 0 ? void 0 : payload.description,
    multipleRequest: payload === null || payload === void 0 ? void 0 : payload.multipleRequest,
    secondTopic: payload === null || payload === void 0 ? void 0 : payload.secondTopic,
    secondDescription: payload === null || payload === void 0 ? void 0 : payload.secondDescription,
    // portalCaseTopic:payload?.portalCaseTopic,
    portalCaseSubTopic: payload !== null && payload !== void 0 && payload.portaPlCaseSubTopic ? payload === null || payload === void 0 ? void 0 : payload.portalCaseSubTopic : payload === null || payload === void 0 ? void 0 : payload.portalCaseTopic,
    attachmentList: payload === null || payload === void 0 ? void 0 : payload.attachmentList
  };
  const response = await api.post(CRM_Service_URL, CrmRequestPayload, data);
  return response;
};
function* getCrmServiceData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(CrmServiceRequestData, payload);
    yield put(submitFormSuccess(response.data));
  } catch (error) {
    yield put(crmServiceRequestFailure(error));
  }
}
export function* watchServiceRequestDate() {
  yield takeLatest(crmServiceRequest.type, getCrmServiceData);
}