import { call, put, takeEvery } from "redux-saga/effects";
import {
  flightInfoFail,
  flightInfoRequest,
  flightInfoSuccess,
} from "../slice/flightInfoSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.FLIGHT_INFO_API;
const fetchFlightInfo = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload.info, headerData);

  return response;
};
function* fetchFlightInfoSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchFlightInfo, payload);
    yield put(
      flightInfoSuccess({
        ...response,
        moreInfo: payload.moreInfo,
        id: payload.id,
      })
    );
  } catch (error: any) {
    yield put(
      flightInfoFail({
        ...error?.response,
        moreInfo: payload.moreInfo,
        id: payload.id,
      })
    );
  }
}

export function* watchFlightInfo() {
  yield takeEvery(flightInfoRequest.type, fetchFlightInfoSaga);
}
