import { call, put, takeEvery } from "redux-saga/effects";
import {
  crmResendOTPPending,
  crmResendOTPSuccess,
  crmResendOTPFail,
} from "../slice/crmResendOTPSlice";
import api from "../../../config/api";
import { CrmResendOTPData } from "../models/models";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config.CRM_RESEND_OTP;
const crmResendOTPData = async (payload: CrmResendOTPData) => {
  let data = {
    headers: {
      Authorization: `${sessionStorage.getItem("temptoken")}`,
      "content-type": "application/json",
    },
  };

  const response = await api.get(API_URL, data);

  sessionStorage.setItem("temptoken", response.headers.authorization);
  return response;
};
function* crmResendOTPSaga(
  action: PayloadAction<CrmResendOTPData>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(crmResendOTPData, payload);
    yield put(crmResendOTPSuccess(response.data));
  } catch (error: any) {
    yield put(crmResendOTPFail(error));
  }
}

export function* watchCrmResendOTP() {
  yield takeEvery(crmResendOTPPending.type, crmResendOTPSaga);
}
