import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import {
    getMemberTierCalclulatorPending,
    getMemberTierCalclulatorSuccess,
    getMemberTierCalclulatorFailure,
} from "../slice/fetchMemberStatusCalculatorSlice";
import { MemberTierCalclulator } from "../models/models";
import { config } from "../../../config/global";


const MEMBER_TIER_CALCULATOR_API_URL = config.MEMBER_TIER_CALCULATOR;

const fetchMemberTierCalclulatorData = async (
  MemberTierCalclulatorPayload: MemberTierCalclulator
) => {
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
    isGenericErr: true,
	loader: true,
  };
   

  const response = await api.post(
    MEMBER_TIER_CALCULATOR_API_URL,
    MemberTierCalclulatorPayload,
    headerData
  );

  return response;
};



function* postMemberTierCalclulatorData(
  action: PayloadAction<MemberTierCalclulator>
): Generator<any, void, any> {
  try {
    const response = yield call(fetchMemberTierCalclulatorData, action.payload);
    yield put(getMemberTierCalclulatorSuccess(response.data));
    console.log("response", response.data);
  } catch (error: any) {
    yield put(getMemberTierCalclulatorFailure(error));
  }
}

export function* watchMemberTierCalclulatorData(): any {
  yield takeLatest(
    getMemberTierCalclulatorPending.type,
    postMemberTierCalclulatorData
  );
}