import { call, put, takeLatest } from "redux-saga/effects";

import {
  headerDataRequest,
  getHeaderDataSuccess,
  getHeaderDataFailure,
  globalSearchPreRenderDataRequest,
  globalSearchPreRenderDataSuccess,
  globalSearchPreRenderDataFailure
} from "../../slice/header/header-slice";

// import api from "../../config/api";
import axios from "axios";

//Data from header json file
const API_URL = "./json/headerDetails.json";
const GLOBAL_SEARCH_PRE_DATA = './assets/json-data/global-search.json';

//getHeaderData generator function
function* getHeaderData(): any {
  try {
    const response: any = yield call(axios.get, API_URL, { baseURL: "/" });
    yield put(getHeaderDataSuccess(response.data));
  } catch (error: any) {
    yield put(getHeaderDataFailure(error.message));
  }
}

//get global search pre render data
function* getGlobalSearchPreRenderData(): any {
  try{
    const response: any = yield call(axios.get, GLOBAL_SEARCH_PRE_DATA, {
      baseURL: "/",
    });
    yield put(globalSearchPreRenderDataSuccess(response.data));
  } catch(error: any) {
    yield put(globalSearchPreRenderDataFailure(error.message));
  }
}

export function* watchHeaderData(): any {
  yield takeLatest(headerDataRequest.type, getHeaderData);
}

export function* watchGlobalSearchPreRenderData(): any {
  yield takeLatest(globalSearchPreRenderDataRequest.type, getGlobalSearchPreRenderData);
}
