import { call, put, takeLatest } from "redux-saga/effects";
import {
  getEReceiptDataRequest,
  getEReceiptDataSuccess,
  getEReceiptDataFailure,
  updateEReceiptDataRequest,
  updateEReceiptDataSuccess,
  updateEReceiptDataFailure,
} from "../slice/eReceiptSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";

function* fetchEReceiptSaga(action: PayloadAction<any>): any {
  try {
    const response = yield call(api.get, action.payload.url, {
      method: "GET",
      responseType: "blob",
    });
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    var blobUrl = URL.createObjectURL(blob);

    const limitDetails = {
      printCount: response.headers.printcount,
      sponsorUpdated: response.headers.sponsorupdated,
      ticketNumber: response.headers.ticketnumber,
    };

    yield put(
      getEReceiptDataSuccess({
        blobUrl: blobUrl,
        actualUrl: action.payload.url,
        profileInfo: action.payload.profileInfo,
        limitDetails: limitDetails,
      })
    );
  } catch (error) {
    let errorMessage;
    if (error?.response?.data instanceof Blob) {
      const errorText = yield error?.response.data.text();
      const errorData = JSON.parse(errorText);
      errorMessage = errorData.message || errorMessage;
    } else {
      errorMessage = error.message || errorMessage;
    }
    yield put(getEReceiptDataFailure(errorMessage));
  }
}

function* updateEReceiptSaga(action: PayloadAction<any>): any {
  try {
    const response = yield call(api.get, action.payload, {
      method: "GET",
    });
    yield put(updateEReceiptDataSuccess(response.data));
  } catch (error) {
    yield put(updateEReceiptDataFailure(error));
  }
}

export function* watchFetchEReceiptSaga(): any {
  yield takeLatest(getEReceiptDataRequest.type, fetchEReceiptSaga);
}

export function* watchUpdateEReceiptSaga(): any {
  yield takeLatest(updateEReceiptDataRequest.type, updateEReceiptSaga);
}
