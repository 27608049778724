import { call, put, takeEvery } from "redux-saga/effects";
import {
  flightFareRequest,
  flightFareSuccess,
  flightFareFail,
} from "../slice/flightFareSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.FLIGHT_FARE_API;
const fetchFlightFare = async (payload: any) => {
  let headerData = {
    headers: {
      memberId: sessionStorage.getItem("memberId"),
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
    },
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchFlightFareSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchFlightFare, payload);
    yield put(
      flightFareSuccess({
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      flightFareFail({
        ...error?.response,
      })
    );
  }
}

export function* watchFlightFare() {
  yield takeEvery(flightFareRequest.type, fetchFlightFareSaga);
}
